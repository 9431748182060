import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import SEO from "../components/elements/SEO";
import Layout from "../components/layout/Layout";
import SectionList from "../components/sections/SectionList";

const query = graphql`
  query {
    strapiContactPage {
      sections {
        ... on StrapiComponentBlocksRegionGrid {
          __typename
          id
          blockInfo {
            background
            description
            anchor
            paddingBottom
            paddingTop
            title
          }
          regions {
            phone
            subtitle
            title
            websiteURL
            email
            description
            icon {
              file {
                publicURL
              }
            }
          }
        }
        ... on StrapiComponentBlocksForm {
          id
          __typename
          postURL
          button {
            label
            outline
            primary
            size
            strapiId
          }
          blockInfo {
            paddingBottom
            paddingTop
            title
            description
            background
            anchor
          }
          fields {
            requestId
            label
            options
            placeholder
            required
            type
            id
            errorMessage
          }
        }
      }
      pageInfo {
        seoTitle
      }
    }
  }
`;

function ContactPage() {
  const { strapiContactPage } = useStaticQuery(query);
  return (
    <Layout>
      <SEO title={strapiContactPage.pageInfo.seoTitle} />
      <SectionList sections={strapiContactPage.sections} />
    </Layout>
  );
}

export default ContactPage;
